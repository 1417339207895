<template>
    <div class="udiomas">
      <button @click="changeLanguage('gl')"   :class="{ 'active': $i18n.locale === 'gl' }">GL</button>
      <button @click="changeLanguage('en')"   :class="{ 'active': $i18n.locale === 'en' }">EN</button>
      <button @click="changeLanguage('es')"   :class="{ 'active': $i18n.locale === 'es' }">ES</button>
    </div>
  </template>
  
  <script>
  export default {
    name:'LanguageSwitcher',
    methods: {
      changeLanguage(language) {
        this.$i18n.locale = language; // Cambia el idioma utilizando vue-i18n
        this.$emit('languageChanged', language); // Emite un evento de cambio de idioma
      },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos opcionales para el componente de cambio de idioma */
  button .idiomas {
    background: aquamarine;
}

.udiomas button {
    background: none;
    color: white;
    border: 0;
    font-family: 'Cuprum';
    font-size: 1.6rem;
}

.udiomas button:active {
    background: none;
    color: #FF5722;
    border: 0;
    font-family: 'Cuprum';
    font-size: 1.6rem;
}
  </style>
  