import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n';

import '@/assets/main.scss'
import 'uikit/dist/css/uikit.css'
import 'uikit/dist/js/uikit-core.min.js'
import 'uikit/dist/js/uikit.min.js'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Configuración de vue-i18n
const i18n = createI18n({
  locale: 'gl', // Establece el idioma predeterminado
  messages: {
    'gl': require('./idiomas/gl.json'), // Importa los mensajes en gallego
    'es': require('./idiomas/es.json'),
    'en': require('./idiomas/en.json'), // Importa los mensajes en inglés
  },
});

const app = createApp(App);

app.use(i18n);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(store).use(router).mount('#app');
