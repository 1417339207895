<template>
  <div id="modal-contacto" class="uk-modal-full" uk-modal>
    <div class="uk-modal-dialog">
      <button
        class="uk-modal-close-full uk-close-large"
        type="button"
        uk-close
      ></button>
      <div class="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" uk-grid>
        <div
          class="uk-background-cover uk-visible@m"
          style="background-image: url('./img/imagenes/fondo043.jpeg')"
          uk-height-viewport
        ></div>
        <div
          class="uk-background-cover uk-height-medium uk-hidden@m"
          style="background-image: url('./img/imagenes/fondo043.jpeg')"
         
        ></div>
        <div class="uk-padding-small">
          <form
            action="https://docs.google.com/forms/d/e/1FAIpQLScn73mc7se8cvTr4BeQEFAcbu5_HnMXkqrB42pgj77j2QZ7yQ/formResponse"
            id="bootstrapForm"
            method="POST"
          >
            <ul class="uk-subnav uk-subnav-pill uk-hidden" uk-switcher>
              <li><a href="#">Item</a></li>
              <li><a href="#">Item</a></li>
              <li><a href="#">Item</a></li>
              <li><a href="#">Item</a></li>
            </ul>
            <ul class="uk-switcher">
              <li>
                <h1 class="uk-font-secondary uk-text-primary">{{ $t('saludo') }}</h1>
                  <p class=" uk-margin-remove-top uk-h4" v-html="$t('intro_form')"></p>
                <fieldset class="uk-fieldset uk-margin">
                  <legend class="uk-legend" for="">{{ $t('email_input') }}</legend>
                  <div class="form-group">
                    <input
                      id="emailAddress"
                      class="di-input form-control"
                      type="email"
                      name="emailAddress"
                      required
                    />
                  </div>
                </fieldset>

                <fieldset class="uk-fieldset">
                  <legend class="uk-legend" for="710353083">{{ $t('nombre_input') }}</legend>
                  <div class="form-group">
                    <input
                      id="1306814443"
                      class="di-input form-control"
                      type="text"
                      name="entry.1306814443"
                      required
                    />
                  </div>
                </fieldset>
        <div class="uk-flex uk-flex-right uk-margin">
                  <a class="bn30" href="#" uk-switcher-item="next">{{ $t('siguiente_b') }}</a>
                </div>
              </li>
              <li>
                <h3 class="uk-margin-remove uk-font-secondary uk-text-primary">Xenial!</h3>
<p class="uk-h4 uk-margin-remove-top" v-html="$t('intro_form2')"></p>
        
                <fieldset class="uk-fieldset">
                 
                  <div
                    class="form-group uk-list uk-list-divider uk-text-uppercase uk-margin"
                  >
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          name="entry.837779539"
                          value="Asesoramento en xeral"
                        
                        />
                        <span v-html="$t('opcion1')"></span>
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          name="entry.837779539"
                          value="Soporte servidor/web"
                        />
                        <span v-html="$t('opcion2')"></span>
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          name="entry.837779539"
                          value="Marketing dixital"
                        />
                        <span v-html="$t('opcion3')"></span>
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          name="entry.837779539"
                          value="Deseño web"
                        />
                        <span v-html="$t('opcion4')"></span>
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          name="entry.837779539"
                          value="Deseño gráfico"
                        />
                        <span v-html="$t('opcion5')"></span>
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          name="entry.837779539"
                          value="Camisolas personalizadas"
                        />
                        <span v-html="$t('opcion6')"></span>
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          name="entry.837779539"
                          value="other_option"
                        />
                        <button
                          class="uk-button uk-button-text"
                          uk-toggle="target: #my-id"
                          type="button"
                        >
                        <span v-html="$t('opcion7')"></span>
                        </button>
                      </label>
                      <div id="my-id" class="uk-margin-small-top" hidden>
                        <input
                          type="text"
                          name="entry.837779539.other_option_response"
                          class="di-input uk-form-small"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div class="uk-button-group uk-flex uk-flex-right">
                  <a href="#" uk-switcher-item="previous" class="bn30">{{ $t('volver_b') }}</a>

                  <a href="#" uk-switcher-item="next" class="bn30">{{ $t('siguiente_b') }}</a>
                </div>
              </li>
              <li>

                <h2 class="uk-font-secondary uk-text-primary">{{ $t('titulo_form3') }}</h2> <p class="uk-text-lead" v-html="$t('intro_form3')"></p>
                <fieldset class="uk-fieldset">
                  
                  <div class="form-group">
                    <textarea
                      id="1637346954"
                      name="entry.1637346954"
                      class="uk-textarea form-control uk-height-small"

                      required
                    ></textarea>
                  </div>
                </fieldset>

                <div class="uk-button-group uk-flex uk-flex-right uk-margin">
                  <a href="#" uk-switcher-item="previous" class="bn30">{{ $t('volver_b') }}</a>

                  <a href="#" uk-switcher-item="next" class="bn30">{{ $t('siguiente_b') }}</a>
                </div>
              </li>
              <li>
                <h3 class="uk-font-secondary">{{ $t('titulo_form4') }}</h3>
                <div class="uk-margin uk-background-secondary uk-text-lead uk-padding uk-box-shadow-xlarge">
                  <p v-html="$t('intro_form4')"></p>
                </div>
              <div class="uk-child-grid-1-2 uk-flex uk-flex-middle" uk-grid>
                <div class="uk-text-left uk-width-1-2">
                  <a href="#"  uk-switcher-item="previous"><svg fill="#fff" width="50px" height="50px" viewBox="0 0 24 24" id="left-sign" data-name="Multi Color" xmlns="http://www.w3.org/2000/svg" class="icon multi-color"><path id="secondary-fill" d="M9.13,12.49,13.86,21H19l-5-9,5-9H13.86L9.13,11.51A1,1,0,0,0,9.13,12.49Z" style="fill: rgb(87 216 140);stroke-width: 1.5;"></path><path id="primary-stroke" d="M14,12l5,9H10L5,12m9,0,5-9H10L5,12" style="fill: none;stroke: rgb(255 255 255);stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5;"></path></svg></a>
                </div>
                <div class="uk-text-right uk-width-1-2">
                  <input type="hidden" name="fvv" value="1" />
                <input type="hidden" name="fbzx" value="-2356243146827267244" />
                <input type="hidden" name="pageHistory" value="0" />
                <input class="bn30" type="submit" v-html="$t('enviar_b')" />
                </div>
              </div>
              
             
                <h1 class="uk-font-secondary uk-text-primary">{{ $t('despedida') }}</h1>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormularioSimple",
};
</script>