<template>
  <div>
    <label class="switch uk-position-relative">
      <input type="checkbox" id="darkModeToggle" v-model="darkModeEnabled" style="display: none;">
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'DarkModeSwitch',
  data() {
    return {
      darkModeEnabled: false,
    };
  },
  watch: {
    darkModeEnabled() {
      document.body.classList.toggle('uk-light', !this.darkModeEnabled);
      document.body.classList.toggle('uk-dark', this.darkModeEnabled);
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 50px;
  height: 25px;
  transition: background-color 0.3s, color 0.3s;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc4a;
  border-radius: 34px;
  transition: 0.4s;
  
}

.slider:before {
  position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 2px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    background-color: #32d297;
    border-radius: 50%;
    transition: 0.3s;
}

input:checked + .slider:before {
  content: "";
  transform: translateX(22px);
  background-color: #32d29675;
}
</style>
