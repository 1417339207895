<template>
  <FondoLumino />
  <div :class="'uk-width-expand'">
    <nav :class="'uk-navbar-container tm-navbar-container uk-sticky uk-navbar-transparent uk-padding-small'" uk-navbar>
     <!-- <div class="uk-navbar-left">

        <a class="uk-navbar-item uk-logo" href="#" aria-label="Back to Home">
          <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 195 118" style="enable-background:new 0 0 195 118;" xml:space="preserve">

            <path class="st0" d="M51.1,76.9c-0.6,12.3-1.9,24.9,7,35.6c0.2,0.3,0.2,0.7-0.2,0.9c-5.2,3.1-6.1,2.1-9.3-2.3
		c-3.5-4.9-2.3-11-5.3-15.8c-3.2-5-3.7-10.6-3.8-16.4c-0.1-1.6-0.7-3.1-1.4-5.1c-0.2-0.5-0.9-0.6-1.1-0.1c-7.2,13-11.1,25.5-3.2,39
		c-0.6,1-0.7,1.2-1.3,2.2c-2.2,0-4.7,0.3-5.8-0.7c-1.8-1.7-3.1-5-3-7.5c0.3-8.1,0.5-16.1-3-23.7c-1.7-3.6-1.2-7.1-0.3-11.1
		c1.1-4.7,0.5-9.9,0.1-14.8c-0.6-7.5-1.7-14.9-2.6-22c-0.1-0.5-0.7-0.7-1-0.4c-1,1-1.5,2.8-1.8,6.6c-0.5,7.4-0.9,14.8-1.6,22.2
		c-0.4,4.7-3.2,8.2-6.4,11.3c-0.2,0.2-0.5,0.2-0.7,0.1l-0.1-0.1C6,74.6,5.9,74.2,6,73.9c0.6-1.3,1.1-2.8,2-3.8
		c2.9-3.4,4.1-7.3,4-11.7c-0.1-4.8,0-9.5-0.5-14.3c-0.7-7.7,2.2-13.4,8.7-17.4c2.9-1.8,6-3.4,8.7-5.5c6-4.7,12.6-6.2,20.1-5.3
		c15.5,1.7,31,0.3,46.2-3c11.9-2.6,23.6-2.2,35.5-0.8c7.4,0.9,15,0.5,22.5,0.8c2.9,0.1,5.7,0.5,8.8,0.8c0.4,0,0.8-0.3,0.7-0.8
		c-0.6-3-2.3-6.2,0.5-9.2c0.4-0.4,1.1-0.1,1.1,0.4c0,1.2,0,2.2,0.3,3.1c0.8,2.8,1.9,5.4,5.8,4.3c1.1-0.3,2.4-0.2,3.6-0.2
		c7.3,0.2,8.8-0.8,9.1-7.3c0-0.5,0.6-0.8,1-0.4c2.6,2.3,2.2,4.8-1.3,10.2c-0.3,0.5,0.1,1.1,0.6,0.9c1.4-0.3,2.9-0.6,4.3-0.8
		c1.7-0.2,3.5-0.2,5.2-0.2c0.2,0,0.6,1.8,0.4,2c-1.6,1.1-3.2,2.2-5,2.8c-1.3,0.4-2.7,0.2-4.5,0.1c-0.4,0-0.7,0.3-0.6,0.6
		c0.2,6,0.2,11.8,5.6,15.9c2.7,2,4.2,4.5,2.7,7.8c-1.4,3.2-4.2,4.3-7.5,4c-1.9-0.2-4-0.5-5.7-1.4c-3.4-1.8-6.9-2-10.4-0.7
		c-7.2,2.9-14.6,5.7-21.3,9.4c-3.3,1.8-5.6,5.5-8,8.6c-1.8,2.4-2.7,5.5-4.5,7.9c-1.4,1.9-3.5,3.4-6.1,4.8c-0.2,0.1-0.4,0.4-0.3,0.7
		c2.4,12.2-1.5,25.9,7.1,37.2c0.2,0.3,0.1,0.7-0.2,0.9c-4.5,2.6-5.6,1.7-8.7-1.8c-2.4-2.7-1.9-5-2-7.8c0-4.5-0.2-8.9-3.7-12.6
		c-1.4-1.5-1.7-4.1-2.6-6.1c-1.2-2.6-2.4-5.2-3.7-7.7c-0.1-0.3-0.5-0.4-0.8-0.3c-0.7,0.3-0.9,0.3-1,0.5c-2.1,5.9-4.9,11.7-6.2,17.8
		c-1,4.5-0.2,9.3,0,14c0,1.2,0.6,2.4,1.2,4.2c0.1,0.4-0.2,0.8-0.6,0.8c-2.6,0-5.7,0.3-6-0.5c-1-2.5-3.4-4.4-2.4-8.2
		c1.3-4.6,1-9.8,1-14.7c0-5.3-0.3-10.5-0.5-15.8c-0.1-1.3-0.4-2.5-0.6-3.6c0-0.2-0.2-0.4-0.4-0.5c-0.4-0.1-0.7-0.2-0.8-0.2
		c-3.9,4.5-9.2,3.5-14.3,3.7c-6.4,0.3-12.9,1.2-19.3,1.5c-3.6,0.2-7.2-0.3-11.5-0.6C51.4,76.3,51.1,76.5,51.1,76.9z M61.2,72.2
		c0.4-0.2,0.5-0.7,0.2-1c-2.1-2.4-3.8-4.6-5.9-6.1c-2.1-1.5-4.9-2.4-7.5-3.1c-1-0.3-2.7,0.6-3.3,1.4c-0.4,0.6,0.2,2.3,0.9,3.2
		c3.2,4,7.7,5.8,12.7,6.6C59.1,73.4,60,72.7,61.2,72.2z M159.2,24.3c0.6-0.6,1.5-1.6,2.4-2.6c0.3-0.3,0.2-0.8-0.2-1
		c-1.1-0.6-2.1-1.2-3.2-1.7c-1.8-1-3.5-2.1-5.4-2.8c-1.2-0.4-3.1-0.8-3.7-0.2c-0.7,0.7-0.9,2.7-0.5,3.8c1.6,3.9,5.2,4.2,10.1,4.7
		C158.8,24.6,159,24.5,159.2,24.3z M129,40c2,1.3,3.5,2.4,4.5,1.1c-3.6-4-10.1-17.1-9.6-19.9c0.1-0.7-1.2-1.5-2.9-1.5l-2.1,0.6
		c-0.3,0.2-0.4,0.6-0.2,0.9C121.4,25.8,126.2,35.3,129,40z M131.9,56.1c-2.3,3-10,12.2-11.8,15.5c-0.3,0.6,0.7,1.1,1.3,0.8
		c7.4-3,13.3-10.7,12.6-15.9C133.9,55.9,132.3,55.6,131.9,56.1z M172.6,26.5l-0.1-1c0-0.3-0.3-0.6-0.6-0.5l-3.7,0.2
		c-0.4,0-0.6,0.4-0.6,0.7c0.2,0.9,0.3,2,0.4,2c1.4-0.1,2.8-0.4,4.2-0.7C172.4,27.2,172.6,26.9,172.6,26.5z" />
          </svg>
        </a>

      </div> -->
      <div class="uk-navbar-center" hidden> 
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </div>
      <div class="uk-navbar-right">
        <LanguageSwitcher />
        <!-- Switch para Modo Claro/Oscuro con iconos -->
        <div class="uk-navbar-switch">
        <!--  <DarkModeSwitch /> -->
        <a class="uk-button uk-button-primary" href="#modal-contacto" uk-toggle>Contacto</a>

        </div>
       
      </div>
    </nav>

  </div>
  <router-view />
</template>

<script>
import DarkModeSwitch from './components/DarkModeSwitch.vue'; // Importa el componente
import FondoLumino from './components/FondoLumino.vue';
import LanguageSwitcher from './components/LanguageSwitcher.vue';
export default {
  name: 'App',
  components: {
    DarkModeSwitch, FondoLumino, LanguageSwitcher,  // Registra el componente para su uso
  },
  data() {
    return {
      darkModeEnabled: false,
    };
  },
};
</script>

<style scoped>/* Agrega estilos específicos del componente aquí si es necesario */</style>