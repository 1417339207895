<template>
  <div class="home ">
    <PresentacionSeccion />
  </div>
</template>

<script>
// @ is an alias to /src
import PresentacionSeccion from '@/components/PresentacionSeccion.vue'

export default {
  name: 'HomeView',
  components: {
    PresentacionSeccion
  }
}
</script>
