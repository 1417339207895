<template>
  <div id="modal-coffe" uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close></button>

      <div class="uk-inline">
        <img
          src="/img/imagenes/leonardo.jpeg"
          width="1800"
          height="400"
          class="image-dialog"
          alt="ilus"
        />
        <div class="uk-padding-small uk-position-bottom">
          <a href="#modal-group-2" class="uk-button uk-button-default" uk-toggle="" role="button" style="background: #aa584c;color: white;">Proxectos</a>
        </div>
      </div>

      <div
        class="uk-modal-body"
        style="background-color: rgb(245 231 212); color: #97684e"
      >
        <p v-html="$t('texto_coffe')"></p>
        <div class="uk-text-center@s uk-text-right@l">
          <a href="https://www.buymeacoffee.com/volaivai">
          <button class="btn">
            <div class="btn__bg">
              <span class="btn__bg__layer btn__bg__layer-first"></span>
              <span class="btn__bg__layer btn__bg__layer-second"></span>
              <span class="btn__bg__layer btn__bg__layer-third"></span>
            </div>

            <span class="btn__text-out">Convidame</span>
            <span class="btn__text-in">a un café</span>
          </button>
          </a>
        </div>
      </div>
      
    </div>
  </div>

  <div id="modal-group-2"  class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog  uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <SlideProyectos />
      <div class="uk-modal-body">
      
        <h2 class="uk-font-secondary uk-margin-remove uk-text-background" uk-slideshow-parallax="x: 200,0,0">VOLAI-VAI</h2>
                <p class="uk-margin-remove" uk-slideshow-parallax="x: 400,0,0;">Proxecto activo dende 2017.<br><a class="uk-button uk-button-small uk-button-primary" href="htpps://volaivai.com">volaivai.com</a></p>
          
      </div>
    </div>
  </div>
</template>

<script>
import SlideProyectos from "@/components/SlideProyectos.vue";

export default {
  name: "FormularioCoffe",
  components: {
    SlideProyectos,
  },
};
</script>

